import React, { FC } from "react";

type Props = {
  onPress: () => void;
  width?: number;
  color?: string;
};

const Icon: FC<Props> = ({ onPress, width = 15, color = "#333333" }) => (
  <svg
    onClick={onPress}
    width={width}
    style={{ cursor: "pointer", margin: 10 }}
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.82307 0.484339L14.5159 12.1772C15.1617 12.823 15.0395 13.9923 14.5159 14.5157C13.8701 15.1614 12.8233 15.1614 12.1775 14.5157L0.48461 2.8228C-0.161176 2.17702 -0.0389281 1.00769 0.48461 0.484339C1.13039 -0.161446 2.17729 -0.161446 2.82307 0.484339Z"
      fill={color}
    />
    <path
      d="M0.484339 12.1767L12.1772 0.483877C12.823 -0.161908 13.9923 -0.0396605 14.5157 0.483877C15.1614 1.12966 15.1614 2.17655 14.5157 2.82234L2.8228 14.5152C2.17702 15.161 1.00769 15.0387 0.484339 14.5152C-0.161446 13.8694 -0.161446 12.8225 0.484339 12.1767Z"
      fill={color}
    />
  </svg>
);

export default Icon;
