import React, { FC } from 'react';
import { Content, Iframe, Text} from './styles';

type Props = {};

const Dashboard: FC<Props> = () => (
  <Content>
    <div style={{ 
      backgroundColor: '#183e62', 
      borderRadius: '5px', 
      height: '260vh', 
      display: 'flex', 
      justifyContent: 'left', 
      alignItems: 'center', 
      padding: '1%'}}>
      <Iframe 
        src="https://7699e9b9-0ac7-4ab9-8b0c-d76293b7543e.primecontrol.com.br/s/iqd---demo/app/dashboards#/view/98ad4d1e-8e62-5574-b82f-1d48ea6b76c2?embed=true&_g=()&_a=()&hide-filter-bar=true"
        style={
          {
            width: '100%',
            height: '100%',
            border: 'none'
          }
        }
      />
    </div>
  </Content>
);

export default Dashboard;