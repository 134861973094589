import { getTheme, pxToRem, styled } from '~/utils';

// Query
const inMobile = getTheme('inMobile');

const primaryDark = getTheme('primary.dark');
const primaryLight = getTheme('primary.light');
const mediumSpacing = getTheme('mediumSpacing');
const smallSpacing = getTheme('smallSpacing');
const primaryMain = getTheme('primary.main');
const secondaryDark = getTheme('secondary.dark');
const failure = getTheme('failure');

export const Body = styled.body`
  width: 100vw;
  height: 100vh;
  background-color: ${primaryDark};
  display: flex;
  justify-content: center;

  @media ${inMobile} {
    width: 95%;
    margin: 0 auto;
  }
`;

export const SectionForm = styled.div`
  width: ${pxToRem(400)};
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
`;

export const ContainerFooter = styled.div`
  width: 100%;
  display: flex;
`;

export const ResentCode = styled.h3`
  margin-top: ${smallSpacing};
  color: ${primaryLight};
  font-weight: 400;
  cursor: pointer;
  font-size: ${pxToRem(15)};
  width: 50%;
  text-align: left;

  &:hover {
    color: ${primaryMain};
    transition: 0.3s;
  }
`;

export const TextField = styled.input`
  border: 0;
  border-radius: ${pxToRem(5)};
  width: 100%;
  height: ${pxToRem(48)};
  margin-top: ${mediumSpacing};
  font-weight: 400;
  font-size: ${pxToRem(16)};
  padding: ${smallSpacing};
`;

export const Paragraph = styled.h3`
  margin-top: ${smallSpacing};
  color: ${primaryLight};
  font-weight: 400;
  cursor: pointer;
  font-size: ${pxToRem(15)};
  width: 50%;
  text-align: right;

  &:hover {
    color: ${primaryMain};
    transition: 0.3s;
  }
`;

export const Title = styled.h3`
  padding: 0;
  color: ${primaryLight};
  font-weight: 400;
  margin-top: ${smallSpacing};
`;

export const SectionButtons = styled.div`
  flex-direction: column;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  cursor: pointer;
  font-weight: 600;
  color: ${primaryLight};
  font-size: ${pxToRem(16)};
  border: 0;
  border-radius: ${pxToRem(5)};
  width: 100%;
  height: ${pxToRem(48)};
  margin-top: ${smallSpacing};
  background-color: ${(props) =>
    props.disabled ? '#FD784E95' : primaryMain(props)};

  &:hover {
    background-color: ${(props) =>
      props.disabled ? primaryMain(props) : secondaryDark(props)};
    transition: 1s;
  }
`;

export const TextError = styled.h3`
  width: 100%;
  color: ${failure};
  font-weight: 400;
  font-size: ${pxToRem(14)};
`;
