import { getTheme, styled } from '~/utils';

const inMobile = getTheme('inMobile');

const primaryDark = getTheme('primary.dark');

// Spacing
const largeSpacing = getTheme('largeSpacing');
const mediumSpacing = getTheme('mediumSpacing');
const smallSpacing = getTheme('smallSpacing');

export const Content = styled.div`
  width: 100%;
  height: rem(100vh);
  background-color: ${primaryDark};
  display: flex;
  flex-direction: column;
`;

export const Body = styled.div`
  width: 100%;
  height: 100%;
  padding: ${largeSpacing};
  flex-direction: column;

  @media ${inMobile} {
    padding: ${smallSpacing};
  }
`;
