import CryptoJS from 'crypto-js';
import { REACT_APP_KEY_CIPHER } from '../enums';

export const encondingBase64 = (data: string): string =>
  CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data));

export const decodingBase64 = (data: string): string =>
  CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(data));

export const cipherText = (data: string): string => {
  const text = CryptoJS.AES.encrypt(data, REACT_APP_KEY_CIPHER).toString();

  const cipher = CryptoJS.DES.encrypt(text, REACT_APP_KEY_CIPHER).toString();

  return encondingBase64(cipher);
};

export const decipherText = (data: string): string => {
  const text = decodingBase64(data);

  const origin = CryptoJS.DES.decrypt(text, REACT_APP_KEY_CIPHER).toString(
    CryptoJS.enc.Utf8,
  );

  return CryptoJS.AES.decrypt(origin, REACT_APP_KEY_CIPHER).toString(
    CryptoJS.enc.Utf8,
  );
};
