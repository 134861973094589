import React, { FC, useCallback, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { CircularProgress } from '@material-ui/core';
import { Aceleradores } from '~/assets/svg';
import colors from '~/theme/colors';
import { Path } from '~/routes/routes.path';
import { useLocation } from 'react-router-dom';
import { alert, useStores } from '~/utils';
import { Amplify } from '~/services';
import { If } from '~/components';
import {
  Body,
  Button,
  SectionButtons,
  SectionForm,
  TextError,
  TextField,
  Title,
  Paragraph,
  ContainerFooter,
  ResentCode,
} from './styles';

const amplify = new Amplify();

const VerifyAccount: FC = (): JSX.Element => {
  const [resendCode, setResendCode] = useState(false);
  const { routing } = useStores();

  const onLogin = () => {
    routing.push(Path.LOGIN);
  };
    
  const { values, errors, submitForm, handleChange, isSubmitting } =
  useFormikContext<User.VerifyAccount>();
    
  const onResendCode = async () => {
    const send = await amplify.resendCode({ email: values.username }).catch(
      (error) => {
        setTimeout(() => {
          if (error.message === 'Attempt limit exceeded, please try after some time.') {
            alert({
              message: 'Limite de tentativas atingido, tente novamente após 15 minutos',
              type: 'error',
              position: 'bottom-center',
            });
          } else {
            alert({
              message: error,
              type: 'error',
              position: 'bottom-center',
            });
          };
        }, 7000);
      }
    );
    if (send !== undefined) {
      setResendCode(true);
      alert({
        message: 'Código de validação reenviado, aguarde 30 segundos para tentar novamente',
        type: 'warning',
        position: 'bottom-center',
      });
      setTimeout(() => {
        setResendCode(false);
      }, 30000);
    }
  }

  return (
    <Body>
      <SectionForm>
        <Aceleradores />
        <Title>Validação de conta</Title>

        <TextField
          name="confirmationCode"
          placeholder="Codigo de verificação"
          type="text"
          value={values.confirmationCode}
          onChange={handleChange('confirmationCode')}
        />
        <TextError>{errors.confirmationCode}&nbsp;</TextError>

        <SectionButtons>
          <Button onClick={submitForm}>
            {isSubmitting ? (
              <CircularProgress
                size={25}
                style={{ color: colors.primary.light }}
              />
            ) : (
              'Verificar conta'
            )}
          </Button>
        </SectionButtons>
        <ContainerFooter>
          <If condition={!resendCode}>
            <ResentCode onClick={onResendCode}>
              Reenviar código de verificação
            </ResentCode>
          </If>
          <If condition={resendCode}>
            <ResentCode>
              Código reenviado
            </ResentCode>
          </If>
          <Paragraph onClick={onLogin}>Cancelar</Paragraph>
        </ContainerFooter>
      </SectionForm>
    </Body>
  );
};

export default VerifyAccount;
