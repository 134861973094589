import React, { FC, useEffect } from 'react';
import { Formik } from 'formik';
import { Path } from '~/routes/routes.path';
import { useLocation } from 'react-router-dom';
import { Amplify } from '~/services';
import { alert, useStores, yup } from '~/utils';
import VerifyAccount from './VerifyAccount';

type Props = {};

const validate = yup.object().shape({
  confirmationCode: yup.string().required('Código é obrigatório'),
});

const amplify = new Amplify();

const VerifyAccountContainer: FC<Props> = () => {
  const { routing, user } = useStores();

  const {
    state: { username },
  } = useLocation();

  const onVerifyAccount = async (data: User.VerifyAccount) => {
    data.username = username;
    const confirmed = await amplify.verifyAccount(data);

    if (confirmed) {
      alert({
        message: 'Verificado com sucesso',
        type: 'success',
        position: 'bottom-center',
      });

      routing.push(Path.LOGIN);
    } else {
      alert({
        message: 'Erro ao verificar conta',
        type: 'error',
        position: 'bottom-center',
      });
    }
  };

  return (
    <>
    <Formik
      onSubmit={onVerifyAccount}
      validationSchema={validate}
      initialValues={{ username: username, confirmationCode: ''}}
    >
      <VerifyAccount />
    </Formik>
    </>
  );
};

export default VerifyAccountContainer;
