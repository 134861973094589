import styled from "styled-components";
import { getTheme } from '~/utils';

const inMobile = getTheme('inMobile');

const smallSpacing = getTheme('smallSpacing');

export const Content = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Text = styled.h4`
  font-weight: 300;
  color: white;
  font-size: 0.8rem;
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: 2400px;
  border: none;
  @media ${inMobile} {
    height: 4000px;
  }
`;