import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { Aceleradores } from '~/assets/svg';
import { Path } from '~/routes/routes.path';
import { useStores } from '~/utils';
import {
  Body,
  Button,
  SectionButtons,
  SectionForm,
  TextError,
  Paragraph,
  TextField,
  Title,
} from './styles';

const Register: FC = (): JSX.Element => {
  const { routing } = useStores();
  const { submitForm, errors, values, handleChange, isSubmitting } =
    useFormikContext<User.SignUP>();

  const onLogin = () => {
    routing.push(Path.LOGIN);
  };

  return (
    <Body>
      <SectionForm>
        <Aceleradores />
        <Title>Criar Conta</Title>

        <TextField
          name="email"
          placeholder="Email"
          type="email"
          value={values.email}
          onChange={handleChange('email')}
        />
        <TextError>{errors.email}&nbsp;</TextError>

        <TextField
          name="password"
          placeholder="Senha"
          type="password"
          value={values.password}
          onChange={handleChange('password')}
        />
        <TextError>{errors.password}&nbsp;</TextError>

        <SectionButtons>
          <Button disabled={isSubmitting} onClick={submitForm}>
            Criar Conta
          </Button>
        </SectionButtons>
        <Paragraph onClick={onLogin}>Voltar</Paragraph>
      </SectionForm>
    </Body>
  );
};

export default Register;
