// src/components/CustomModal/index.tsx
import React from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';

export interface CustomModalProps {
  title: string;
  visible: boolean;
  onCancel: () => void;
  children?: React.ReactNode; 
}

const ModalWrapper = styled.div`

  flex-direction: column;
  height: 100%;
`;

const TitleContainer = styled.div`
  padding: 16px;
  border-bottom: 1px solid #f0f0f0; 
  background: #fafafa; 
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  display: block !important;
  `;

const ContentContainer = styled.div`
  flex: 1;
  overflow: auto; 
  padding: 16px;
  `;

const FooterContainer = styled.div`
  padding: 16px;
  border-top: 1px solid #f0f0f0; 
  background: #fafafa; 
  text-align: center;
`;

const CustomModal: React.FC<CustomModalProps> = ({ title, children, visible, onCancel }) => (
  <Modal
    title={null}
    open={visible}
    onCancel={onCancel}
    footer={null}
    width={800}
    style={{ display: 'block' }} 
    
  >
    <ModalWrapper>
      <TitleContainer>{title}</TitleContainer>
      <ContentContainer>
        {children}
        {/**/}
      </ContentContainer>

    </ModalWrapper>
  </Modal>
);

export default CustomModal;