import { Request } from '~/services';

// Função para obter os tenants do usuário
export const getUserTenants = async () => {
    try {
        const { data } = await Request.get('/tenant');
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

// Função para obter os apps de um tenant específico
export const getTenantInfo = async (tenantId: string) => {
    try {
        const { data } = await Request.get(`/tenant/${tenantId}/info`);
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

// Função para obter a configuração de um tenant específico
export const getTenantConfig = async (tenantId: string) => {
    try {
        const { data } = await Request.get(`/tenant/${tenantId}/cfg`);
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

// Função para salvar a configuração de um tenant específico usando PATCH
export const saveTenantConfig = async (tenantId: string, cfg: any) => {
    try {
        const token = localStorage.getItem('auth_token'); // Assumindo que o token é armazenado no localStorage
        await Request.patch(`/tenant/${tenantId}/cfg`, cfg, {
            headers: {
                Authorization: `Bearer ${token}` // Adicionando o token no cabeçalho da requisição
            }
        });
    } catch (error) {
        throw new Error(error);
    }
};