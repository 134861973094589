export const Path = {
  HOME: '/',
  LOGIN: '/login',
  RECOVERY_PASSWORD: '/recovery-password',
  VERIFY_ACCOUNT: '/verify-account',
  REGISTER: '/register',
  PRIVATE: '/private',
  PUBLIC: '/public',
  UNITTEST: '/unit-test',
  IQD: '/iqd',
  SELECT_GROUP: '/select-group',
  CONFIG: '/config',
  ORQUESTRADOR: '/orquestrador'

};
