import { Amplify } from 'aws-amplify';
import {
  AuthUser,
  confirmResetPassword,
  ResetPasswordOutput,
  resetPassword,
  SignInOutput,
  signIn,
  signOut,
  signUp,
  getCurrentUser,
  fetchAuthSession,
  confirmSignUp,
  ConfirmSignUpOutput,
  resendSignUpCode,
} from 'aws-amplify/auth';
import {
  AMPLIFY_ENUM,
  REACT_APP_COGNITO_POOL_CLIENT_ID,
  REACT_APP_COGNITO_POOL_ID,
  REACT_APP_DOMAIN,
  alert,
  } from '~/utils';
  import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
  import { CookieStorage } from 'aws-amplify/utils';

class AmplifyAuth {
  constructor() {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: REACT_APP_COGNITO_POOL_ID || '',
          userPoolClientId: REACT_APP_COGNITO_POOL_CLIENT_ID || '',
        }
      },
    });
    cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage({ domain: REACT_APP_DOMAIN, secure: true }));
  }

  signIn = async ({
    email,
    password,
  }: User.SignIn): Promise<SignInOutput | null> => {
    try {
      const logged = await signIn({
        username: email,
        password,
      });
      return logged;
    } catch ({ message }: any) {
      if (message === 'Incorrect username or password.') {
        alert({
          message: 'Usuário ou senha incorretos.',
          type: 'error',
          position: 'bottom-center',
        });
      } else {
        alert({ 
          message: message,
          type: 'error',
          position: 'bottom-center',
         });
      }
      return null;
    }
  };

  // logged
  verifyUser = async (): Promise<boolean> => {
    try {
      const user = await getCurrentUser();
      return true;
    } catch (error) {
      return false;
    }
  };

  userInfo = async (): Promise<User.UserInfOutput> => {
    const auth = await fetchAuthSession();
    const { username } = await getCurrentUser();

    const {
      tokens: { accessToken },
    } = auth as User.UserInfo;

    return {
      username,
      groups: accessToken.payload['cognito:groups']
    };
  };

  signUp = async ({ email, password }: User.SignUP): Promise<boolean> => {
    const data = await signUp({
      username: email,
      password,
      options: {
        userAttributes: {
          email,
        },
        autoSignIn: true,
      },
    })
      .catch((error) => {
        if (error.message === 'User already exists') {
          alert({
            message: 'Email indisponível',
            type: 'error',
            position: 'bottom-center',
          });

          return false;
        }

        if (error.message.includes('Email address is not verified')) {
          alert({
            message: 'Conta criada com Sucesso, aguarde a confirmação.',
            type: 'info',
            position: 'bottom-center',
          });
          return false;
        }
      })
      .then((res) => res);

    return !!data;
  };

  logout = async (): Promise<boolean> => {
    try {
      await signOut({ global: true });
      return true;
    } catch (error) {
      return false;
    }
  };

  resetPasswordSendCode = async (
    username: string,
  ): Promise<ResetPasswordOutput> => {
    const data = await resetPassword({ username });

    return data;
  };

  resetPasswordConfirm = async ({
    code,
    newPassword,
    email,
  }: User.ResetPasswordConfirm): Promise<boolean> => {
    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: code,
        newPassword,
      });
      return true;
    } catch (error) {
      return false;
    }
  };

  getToken = async () => {
    try {
      const session = await fetchAuthSession();   // Fetch the authentication session
      return session.tokens.accessToken.toString();
    }
    catch (e) { return '' }
  };

  verifyAccount = async ({
    username,
    confirmationCode
  }: User.VerifyAccount): Promise<ConfirmSignUpOutput | null> => {
    try {
      const response = await confirmSignUp({username, confirmationCode});
      return response;
    }
    catch (e) { return null; }
  }

  resendCode = async ({
    email
  }) => {
      const response = await resendSignUpCode({username: email})
      return response;
  };

}

export default AmplifyAuth;
