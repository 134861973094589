import { ButtonBase, Icon } from '@material-ui/core';
import { getTheme, pxToRem, styled } from '~/utils';

// Colors
const primaryLight = getTheme('primary.light');
const secondaryDark = getTheme('secondary.dark');
const primaryMain = getTheme('primary.main');

// Spacing
const smallSpacing = getTheme('smallSpacing');

// Query
const inMobile = getTheme('inMobile');

export const Content = styled.div`
  width: 100%;
  height: ${pxToRem(80)};
  background-color: ${secondaryDark};
  padding: ${smallSpacing};
  justify-content: space-between;
  align-items: center;
  display: flex; /* Adicionado para garantir que os itens filhos estejam em linha */
`;

export const SectionLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ImgIcon = styled.img.attrs({
  alt: '',
})`
  width: ${pxToRem(150)};
  object-fit: contain;
  cursor: pointer;

  @media ${inMobile} {
    width: ${pxToRem(100)};
  }
`;

export const TextProduct = styled.h1`
  margin: 0;
  padding: 0;
  color: ${primaryLight};
  font-size: ${pxToRem(20)};
  padding-left: ${smallSpacing};
  margin-left: ${smallSpacing};
  border-left-width: ${pxToRem(2)};
  border-left-style: ridge;
  border-left-color: ${primaryLight}50;

  @media ${inMobile} {
    font-size: ${pxToRem(14)};
  }
`;

export const SectionIcon = styled(ButtonBase)`
  color: ${primaryMain};
  flex-direction: row;
  align-items: center;
`;

export const NameUser = styled.h5`
  margin: 0;
  color: ${primaryLight};
  align-items: center;
  font-size: ${pxToRem(18)};

  @media ${inMobile} {
    font-size: ${pxToRem(14)};
  }
`;

export const IconStyled = styled(Icon)`
  color: ${primaryLight};
  margin-left: ${smallSpacing};
  cursor: pointer;

  @media ${inMobile} {
    margin-left: ${pxToRem(5)};
  }
`;

export const SectionToolbar = styled.div`
  cursor: pointer;
  height: ${pxToRem(30)};
  margin-left: ${smallSpacing};
  padding-left: ${smallSpacing};
  flex-direction: column;

  @media ${inMobile} {
    height: ${pxToRem(25)};
  }
`;
