import { Icon } from '@material-ui/core';
import { getTheme, pxToRem, styled } from '~/utils';

// Query
const inMobile = getTheme('inMobile');

// Colors
const primaryMain = getTheme('primary.main');

// Spacing
const smallSpacing = getTheme('smallSpacing');
const largeSpacing = getTheme('largeSpacing');

// Radius
const smallRadius = getTheme('smallRadius');

export const Content = styled.div`
  width: 100%;
  height: ${pxToRem(110)};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${largeSpacing};

  @media ${inMobile} {
    width: 97%;
    flex-direction: column;
  }
`;

export const SectionFields = styled.div`
  flex-direction: row;
  align-items: center;

  @media ${inMobile} {
    width: 100%;
  }
`;

export const Input = styled.input`
  border: 0;
  height: ${pxToRem(46)};
  width: ${pxToRem(450)};
  margin-left: ${smallSpacing};
  padding: ${smallSpacing};
  border-radius: ${smallRadius};

  @media ${inMobile} {
    width: 95%;
  }
`;

export const ImgIcon = styled.img.attrs({
  alt: '',
})`
  width: ${pxToRem(200)};
  object-fit: contain;
`;

export const IconStyled = styled(Icon)`
  color: ${primaryMain};
  margin-left: ${smallSpacing};
  cursor: pointer;

  @media ${inMobile} {
    margin-left: ${pxToRem(5)};
  }
`;
