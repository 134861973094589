import Axios from 'axios';
import { interceptorsAuthHeaders } from './interceptors';

const baseURL = process.env.REACT_APP_API_URL;
const xToken = process.env.X_TOKEN;
const orchestratorURL = process.env.REACT_APP_API_ORCHESTRATOR;

export const request = Axios.create({
  baseURL,
  headers: {
    'x-token': xToken,
  },
  timeout: 15000, // 15 seconds timeout
});

export const requestOrchestrator = Axios.create({
  baseURL: orchestratorURL,
  timeout: 15000, // 15 seconds timeout
});

// Adiciona o interceptor de cabeçalh de autorização
request.interceptors.request.use(interceptorsAuthHeaders);
requestOrchestrator.interceptors.request.use(interceptorsAuthHeaders);


export default {request, requestOrchestrator};
