import {RequestOrchestrator} from '~/services';

const BASE_URL = process.env.REACT_APP_API_ORCHESTRATOR;

// Função para obter as tarefas
export const getTasks = async (tenantId: string) => {
  try {
    const response = await RequestOrchestrator.get(`/task/${tenantId}`)
    
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar tarefas:', error);
    throw error;
  }
};

// Função para obter o histórico de uma tarefa
export const getTaskHistory = async (tenantId: string, taskId: string) => {
  try {
    const response = await RequestOrchestrator.get (`/task/${tenantId}/${taskId}/history`); 

    return response.data;
  } catch (error) {
    console.error('Erro ao buscar histórico da tarefa:', error);
    throw error;
  }
};
 
export const getJobName = async (tenantId: string, taskId: string) => {
  try {
    const response = await RequestOrchestrator.get(`/task/${tenantId}/${taskId}`);
    return response.data.job_name; // Supondo que a resposta contenha 'job_name'
  } catch (error) {
    console.error('Erro ao buscar o nome do job:', error);
    throw error;
  }
};

// Função para obter as tarefas PAI de uma tarefa
export const getParentTasks = async (tenantId: string, taskId: string) => {
  try {
    const response = await RequestOrchestrator.get(`/task/${tenantId}/${taskId}/parent`); // Ajuste conforme necessário
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar tarefas PAI:', error);
    throw error;
  }
};


