import React, { FC, useEffect, useState } from 'react';
import { Amplify } from '~/services';
import { Path } from './routes.path';
import { useStores } from '~/utils';
import { Loading } from '~/components';

type Props = {
  render: React.ReactElement;
};
const amplify = new Amplify();

const PrivateRoute: FC<Props> = ({ render }) => {
  const { routing } = useStores();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyAuthSession = async () => {
      const isUserAuthenticated = await amplify.verifyUser();
      if (isUserAuthenticated) {
        setIsLoading(false);
      } else {
        routing.replace(Path.LOGIN);
      }
    };

    verifyAuthSession();
  }, [isLoading]);

  return isLoading ? (<Loading/>) : (render);
};

export default PrivateRoute;
