import React, { FC, useState } from 'react';
import { useFormikContext } from 'formik';
import eyeIcon from '~/assets/icons/icon-eyes.svg';  
import eyeOffIcon from '~/assets/icons/icon-eyes-off.svg';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import { Aceleradores } from '~/assets/svg';
import { Path } from '~/routes/routes.path';
import { colors } from '~/theme';
import { useStores } from '~/utils';
import {
  Body,
  Button,
  ForgotPassword,
  Paragraph,
  SectionButtons,
  SectionForm,
  TextError,
  TextField,
  Title,
  ContainerFooter,
  ButtonRegister,
  IconButton,
  PasswordWrapper,
} from './styles';

const Login: FC = (): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);
  const { routing } = useStores();

  const onForgotPassword = () => {
    routing.push(Path.RECOVERY_PASSWORD);
  };

  const onRegister = () => {
    routing.push(Path.REGISTER);
  };

  const { submitForm, errors, values, handleChange, isSubmitting } =
    useFormikContext<User.SignUP>();

  return (
    <Body>
      <SectionForm>
        <Aceleradores />
        <Title>Acessar Conta</Title>
        <TextField
          name="email"
          placeholder="Email"
          type="email"
          value={values.email}
          onChange={handleChange('email')}
        />
        
        <TextError>{errors.email}&nbsp;</TextError>

        <PasswordWrapper>
        <TextField
          name="password"
          placeholder="Senha"
          type={showPassword ? 'text' : 'password'}
          value={values.password}
          onChange={handleChange('password')}
        />
        
        <IconButton
          onClick={() => setShowPassword((prev) => !prev)} 
        >
              <img
                src={showPassword ? eyeOffIcon : eyeIcon}
                
                style={{ width: 24, height: 24 }}
              />
            </IconButton>
        </PasswordWrapper>
        <TextError>{errors.password}&nbsp;</TextError>
       

        <SectionButtons>
          <Button onClick={submitForm}>
            {isSubmitting ? (
              <CircularProgress
                size={25}
                style={{ color: colors.primary.light }}
              />
            ) : (
              'Acessar'
            )}
          </Button>

        </SectionButtons>
        <SectionButtons>
          <ButtonRegister onClick={onRegister}>Cadastrar
          </ButtonRegister>

        </SectionButtons>
        <ContainerFooter>
          <ForgotPassword onClick={onForgotPassword}>
            Esqueci minha senha
          </ForgotPassword>
        </ContainerFooter>
      </SectionForm>
    </Body>
  );
};

export default Login;