import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '~/utils';
import { Button, TextField, Switch, FormControlLabel } from '@material-ui/core';
import Header from '~/components/Header';
import { encryptData } from '~/utils/encryptionUtils';
import './style.css';

const TenantConfig = () => {
  const { user } = useStores();
  const [isJiraEnabled, setIsJiraEnabled] = useState(false);
  const [jiraConfig, setJiraConfig] = useState({
    url: '',
    email: '',
    token: '',
    projects_keys: '',
  });
  const [error, setError] = useState('');

  useEffect(() => {
    const tenantId = localStorage.getItem('tenant_id');
    if (tenantId) {
      user.fetchConfig(tenantId).then(config => {
        if (config?.jira_cfg) {
          setJiraConfig(config.jira_cfg);
          setIsJiraEnabled(config.jira_cfg.activated);
        }
      });
    }
  }, [user]);

  const handleSave = async () => {
    if (!jiraConfig.url || !jiraConfig.email || !jiraConfig.token || !jiraConfig.projects_keys) {
      setError('Todos os campos são obrigatórios.');
      return;
    }

    const encryptedConfig = {
      ...jiraConfig,
      email: encryptData(jiraConfig.email, jiraConfig.email),
      token: encryptData(jiraConfig.token, jiraConfig.email),
    };

    try {
      await user.saveTenantConfig({ jira_cfg: { ...encryptedConfig, activated: isJiraEnabled } });
      alert('Configuração salva com sucesso!');
      setError('');
    } catch (error) {
      console.error('Erro ao salvar configuração:', error);
    }
  };

  const handleChange = (field: string, value: any) => {
    setJiraConfig({ ...jiraConfig, [field]: value });
  };

  const toggleJiraEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsJiraEnabled(event.target.checked);
  };

  return (
    <div className="tenant-config">
      <Header showConfig={false} pageTitle="Configuração do Workspace" />
      <div className="config-content">
        <div className="jira-toggle">
          <div>Integração com o JIRA</div>
          <FormControlLabel
            control={
              <Switch
                checked={isJiraEnabled}
                onChange={toggleJiraEnabled}
                color="primary"
              />
            }
            label=""
          />
        </div>

        {isJiraEnabled && (
          <div className="jira-config">
            <TextField
              label="Jira URL"
              value={jiraConfig.url}
              onChange={(e) => handleChange('url', e.target.value)}
              fullWidth
              required
            />
            <TextField
              label="Jira Email"
              value={jiraConfig.email}
              onChange={(e) => handleChange('email', e.target.value)}
              fullWidth
              required
            />
            <TextField
              label="Jira Token"
              type="password"
              value={jiraConfig.token}
              onChange={(e) => handleChange('token', e.target.value)}
              fullWidth
              required
            />
            <TextField
              label="Projetos"
              value={jiraConfig.projects_keys}
              onChange={(e) => handleChange('projects_keys', e.target.value)}
              fullWidth
              required
            />
            <Button 
              onClick={handleSave} 
              variant="contained" 
              color="primary"
              style={{ marginTop: '10px' }}
            >
              Salvar
            </Button>
            {error && <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(TenantConfig);