import styled from 'styled-components';

export const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  background-color: #183e62;
  color: #2f3e46;
  text-align: center;
`;

export const Logo = styled.img`
  width: 150px;
  margin-bottom: 1rem;
`;

export const SocialIcons = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #183e62;
    transition: background-color 0.3s;

    &:hover {
      background-color: #183e62;
    }
  }
`;

export const SocialIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const Copyright = styled.div`
  font-size: 0.875rem;
  color: white;
`;