import { action, makeObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { Amplify, Storage } from '~/services';
import { getUserTenants, getTenantConfig, saveTenantConfig, getTenantInfo} from '~/services/api/tenant'; 

const amplify = new Amplify();

export default class UserStore {
  [x: string]: any;

  @observable
  data: Partial<User.UserInfOutput> = {};

  @observable
  tenants: Partial<User.Tenant[]> = [];

  @observable
  tenantConfig: any = {}; 

  @observable
  tenantApps: any = [];

  @observable
  tenantLevel: number = 0;

  constructor() {
    makeObservable(this);

    makePersistable(this, {
      name: 'user',
      properties: ['data', 'tenants', 'tenantConfig'], 
      storage: window.localStorage,
    });
  }

  @action
  onClearUserData = (): void => {
    this.data = {};
  }

  @action
  onSetUserData = (data: Partial<User.UserInfOutput>): void => {
    this.data = data;
  };

  @action
  onCheckSession = async (): Promise<boolean> => {
    const success = await amplify.verifyUser();
    return !!success;
  };

  @action
  logout = (): void => {
    const success = amplify.logout();
    if (success) Storage.clearStorage();
  };
  
  @action
  fetchTenantConfig = async (tenantId: string): Promise<void> => {
    try {
      const config = await getTenantConfig(tenantId);
      this.tenantConfig = config;
    } catch (error) {
      console.error('Failed to fetch tenant config', error);
    }
  };

  @action
  fetchUserTenants = async (): Promise<void> => {
    try {
      const tenants = await getUserTenants();
      this.tenants = tenants;
    } catch (error) {
      console.error(error);
    }
  };

  @action
  fetchTenantInfo = async (tenantId): Promise<[]> => {
    const info = await getTenantInfo(tenantId);
    this.tenantApps = info.apps;
    this.tenantLevel = info.level; // Atualiza o nível do usuário
    return this.tenantApps;
  }

  @action
  fetchConfig = async (tenantId: string): Promise<{ jira_cfg?: any }> => {
    try {
      const config = await getTenantConfig(tenantId);
      this.tenantConfig = config;
      return config;
    } catch (error) {
      console.error(error);
      return {}; // Retorna um objeto vazio em caso de erro
    }
  };
  

  @action
  saveTenantConfig = async (config: any): Promise<void> => {
      try {
          const tenantId = localStorage.getItem('tenant_id'); 
          if (tenantId) {

            const projects_keys = config.jira_cfg.projects_keys.split(',');

              const payload = {
                  jira_cfg: {
                      activated: config.jira_cfg?.activated,
                      url: config.jira_cfg?.url,
                      email: config.jira_cfg?.email,
                      token: config.jira_cfg?.token,
                      projects_keys: projects_keys || [],  
                  },
              };
              await saveTenantConfig(tenantId, payload);
              this.tenantConfig = config;
          }
      } catch (error) {
          console.error('Failed to save tenant config', error);
          throw error;
      }
  }
}