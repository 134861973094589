import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Home, Login, RecoveryPassword, Register, VerifyAccount, UnitTest, Iqd, GroupSelection, TenantConfig, OrquestradorRPA } from '~/scenes';
import Private from './private.route';
import RouterProvider from './provider';
import Public from './public.route';
import { Path } from './routes.path';


const NavigationApp: FC = () => (
  <Routes>
    <Route path={Path.REGISTER} element={<Public render={<Register />} />} />

    <Route path={Path.LOGIN} element={<Public render={<Login />} />} />

    <Route path={Path.RECOVERY_PASSWORD} element={<Public render={<RecoveryPassword />} />} />

    <Route path={Path.VERIFY_ACCOUNT} element={<Public render={<VerifyAccount />} />} />

    <Route path={Path.HOME} element={<Private render={<Home />} />} />

    <Route path={Path.UNITTEST} element={<Private render={<UnitTest />} />} />

    <Route path={Path.IQD} element={<Private render={<Iqd />} />} />

    <Route path={Path.SELECT_GROUP} element={<Private render={<GroupSelection />} />} />

    <Route path={Path.CONFIG} element={<Private render={<TenantConfig />} />} /> 
    
    <Route path={Path.ORQUESTRADOR} element={<Private render={<OrquestradorRPA />} />} />
  </Routes>
);
export { RouterProvider };

export default NavigationApp;
