import React, { FC, useState } from 'react';
import { Header, AppInfo } from '~/components';
import { Body, Content, Section, Tool, ToolTitle, ToolDescription, ButtonLink, ToolImage, TextContainer, CarouselContainer } from './styles';
import { CLIENTS, useStores } from '~/utils';
import { observer } from 'mobx-react';
import Footer from '~/components/Footer';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 

import { Carousel } from 'react-responsive-carousel';

import CodiumImg from '~/assets/img/Codium.png';
import CopilotImg from '~/assets/img/Copilot.png';
import CodeWhispererImg from '~/assets/img/CodeWhisperer.png';

import Image1 from '~/assets/img/1.png';
import Image2 from '~/assets/img/2.png';
import Image3 from '~/assets/img/3.png';

const UnitTest: FC = (): JSX.Element => {
  const { user, routing } = useStores();
  const [apps, setApps] = useState(CLIENTS.PRIME.apps);

  return (
    <Content>
      <Header />

      <Body>
        <CarouselContainer>
          <Carousel 
          autoPlay 
          infiniteLoop 
          interval={2500} 
          showThumbs={false}  
          showStatus={false} 
          stopOnHover={false}
          showArrows={false} 
          showIndicators={false}> 
            <div>
              <img src={Image1} alt="Imagem 1" />
            </div>
            <div>
              <img src={Image2} alt="Imagem 2" />
            </div>
            <div>
              <img src={Image3} alt="Imagem 3" />
            </div>
          </Carousel>
        </CarouselContainer>
        <br/>
        <br/>
        <h1>Unit Test</h1>
        <h2>Revolucionando a Programação com Codium, Copilot e CodeWhisperer</h2>
        <br/>
        <br/>
        <br/>
        <br/>
        <Section>
          <Tool>
            <TextContainer>
              <ToolTitle>Codium</ToolTitle>
              <br/>
              <ToolDescription>
                Codium é uma ferramenta de autocompletar para editores de código, especialmente focada em melhorar a produtividade dos desenvolvedores. Usando inteligência artificial e aprendizado de máquina, Codium oferece sugestões de código em tempo real, ajudando a reduzir erros e acelerar o processo de codificação.
              </ToolDescription>
              <ButtonLink href="https://www.codium.ai" target="_blank">Saiba mais</ButtonLink>
            </TextContainer>
            <ToolImage src={CodiumImg} alt="Codium" />
          </Tool>
        </Section>

        <Section>
          <Tool>
            <TextContainer>
              <ToolTitle>Copilot</ToolTitle>
              <br/>
              <ToolDescription>
                GitHub Copilot é um assistente de programação baseado em inteligência artificial desenvolvido pela GitHub em parceria com a OpenAI. Ele integra-se diretamente com o editor de código Visual Studio Code, e fornece sugestões de código, autocompletar, e até gera trechos de código inteiros com base no contexto do código que o desenvolvedor está escrevendo.
              </ToolDescription>
              <ButtonLink href="https://www.copilotai.com/" target="_blank">Saiba mais</ButtonLink>
            </TextContainer>
            <ToolImage src={CopilotImg} alt="Copilot" />
          </Tool>
        </Section>

        <Section>
          <Tool>
            <TextContainer>
              <ToolTitle>CodeWhisperer</ToolTitle>
              <br/>
              <ToolDescription>
                Amazon CodeWhisperer é uma ferramenta de desenvolvimento baseada em inteligência artificial criada pela AWS. Ela oferece sugestões de código, autocompletar, e ajuda na escrita de código em diversos idiomas de programação. O objetivo do CodeWhisperer é aumentar a produtividade dos desenvolvedores e ajudar a escrever código mais rapidamente e com menos erros.
              </ToolDescription>
              <ButtonLink href="https://aws.amazon.com/pt/codewhisperer/" target="_blank">Saiba mais</ButtonLink>
            </TextContainer>
            <ToolImage src={CodeWhispererImg} alt="CodeWhisperer" />
          </Tool>
        </Section>

        <AppInfo />
      </Body>

      <Footer />
    </Content>
  );
};

export default observer(UnitTest);