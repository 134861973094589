import React, { FC, useState, useEffect } from 'react';
import { Menu, MenuItem, Toolbar } from '@material-ui/core';
import { PrimeLogon } from '~/assets/img';
import { Path } from '~/routes/routes.path';
import { device } from '~/theme/breakpoints';
import { useStores } from '~/utils';
import If from '../If';
import {
  Content,
  IconStyled,
  ImgIcon,
  NameUser,
  SectionIcon,
  SectionLogo,
  SectionToolbar,
  TextProduct,
} from './styles';
import { observer } from 'mobx-react';
import { Amplify, Storage } from '~/services';
import { cleanTenantIdCookie } from '~/utils/tenant';

const amplify = new Amplify();

// Define a interface para as props
interface HeaderProps {
  showConfig?: boolean;
  pageTitle?: string;
}

const Header: FC<HeaderProps> = ({ showConfig = true, pageTitle }) => {
  const { routing, user } = useStores();
  const [toolbarOpened, setToolbar] = useState(false);
  const [tenantName, setTenantName] = useState('');

  useEffect(() => {
    const storedTenantName = localStorage.getItem('tenant_name');
    if (storedTenantName) {
      setTenantName(storedTenantName);
    }
  }, []);

  const handleToolbar = () => {
    setToolbar(!toolbarOpened);
  };

  const onLogout = async () => {
    await amplify.logout();
    Storage.clearStorage();
    routing.replace(Path.LOGIN);
  };

  const onHome = () => {
    routing.replace(Path.HOME);
  };

  const onBackToSelection = () => {
    localStorage.removeItem('tenant_name'); // Opcional: Remover o tenant_name do localStorage ao voltar
    routing.replace('/select-group');
  };

  const onConfig = () => {
    routing.replace('/config');
  };

  const isAdmin = user.tenantLevel === 1;

  return (
    <Content>
      <SectionLogo>
        <ImgIcon src={PrimeLogon} title="Home" onClick={onHome} />
        <TextProduct>
          Portal de Aceleradores e IA 
          {tenantName && ` | ${tenantName}`}
          {pageTitle && ` | ${pageTitle}`}
        </TextProduct>
      </SectionLogo>

      <SectionToolbar>
        <SectionIcon onClick={handleToolbar}>
          <If condition={!device.isMobile}>
            <NameUser>{user.data?.username}</NameUser>
          </If>
          <IconStyled>expand_more</IconStyled>
        </SectionIcon>

        <Toolbar onClick={handleToolbar}>
          <Menu
            open={toolbarOpened}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            style={{ marginTop: 50, width: 200 }}
          >
            {tenantName && isAdmin && (
              <MenuItem style={{ width: 230 }} onClick={onConfig}>
                Configuração
              </MenuItem>
            )}
            {tenantName && (
              <MenuItem style={{ width: 180 }} onClick={onBackToSelection}>
                Trocar Workspace
              </MenuItem>
            )}
            <MenuItem style={{ width: 150 }} onClick={onLogout}>
              Sair
            </MenuItem>
          </Menu>
        </Toolbar>
      </SectionToolbar>
    </Content>
  );
};

export default observer(Header);