import { RouterStore } from 'mobx-react-router';
import UserStore from './user.store';

class RootStore {
  user: UserStore;

  constructor() {
    this.user = new UserStore();
  }
}

const store = new RootStore();

export { RouterStore, UserStore };

export default store;
