import React, { FC, useState } from 'react';
import { useFormikContext } from 'formik';
import { CircularProgress } from '@material-ui/core';
import { Aceleradores } from '~/assets/svg';
import { If } from '~/components';
import { Amplify } from '~/services';
import colors from '~/theme/colors';
import { AMPLIFY_ENUM, alert } from '~/utils';
import { Path } from '~/routes/routes.path';
import { useStores } from '~/utils';
import {
  Body,
  Button,
  SectionButtons,
  SectionForm,
  TextError,
  TextField,
  Title,
  Paragraph,
} from './styles';

const amplify = new Amplify();

const RecoveryPassword: FC = (): JSX.Element => {
  const [sendCode, setSendCode] = useState(false);
  const { routing } = useStores();

  const onLogin = () => {
    routing.push(Path.LOGIN);
  };

  const { values, errors, touched, submitForm, handleChange, isSubmitting } =
    useFormikContext<User.ResetPasswordConfirm>();

  const onSendCode = async () => {
    const response = await amplify.resetPasswordSendCode(values.email);

    alert({
      message: 'Código enviado com sucesso',
      type: 'success',
      position: 'bottom-center',
    });

    if (
      response.nextStep.resetPasswordStep ===
      AMPLIFY_ENUM.CONFIRM_RESET_PASSWORD_WITH_CODE
    ) {
      setSendCode(true);
    }
  };

  return (
    <Body>
      <SectionForm>
        <Aceleradores />
        <Title>Esqueci minha senha</Title>

        <TextField
          name="email"
          placeholder="Email"
          type="email"
          value={values.email}
          onChange={handleChange('email')}
        />
        <TextError>{errors.email}&nbsp;</TextError>

        <If condition={sendCode}>
          <TextField
            name="code"
            placeholder="Código"
            type="text"
            value={values.code}
            onChange={handleChange('code')}
          />

          <TextError>{touched.code ? errors.code : ''}&nbsp;</TextError>

          <TextField
            name="newPassword"
            placeholder="Nova Senha"
            type="password"
            value={values.newPassword}
            onChange={handleChange('newPassword')}
          />

          <TextError>
            {touched.newPassword ? errors.newPassword : ''}&nbsp;
          </TextError>
        </If>

        <SectionButtons>
          <If condition={!sendCode}>
            <Button onClick={onSendCode}>Enviar Código</Button>
          </If>

          <If condition={sendCode}>
            <If condition={isSubmitting}>
              <CircularProgress
                size={25}
                style={{ color: colors.primary.light }}
              />
            </If>
            <If condition={!isSubmitting}>
              <Button onClick={submitForm}>Alterar Senha</Button>
            </If>
          </If>
        </SectionButtons>
        <Paragraph onClick={onLogin}>Voltar</Paragraph>
      </SectionForm>
    </Body>
  );
};

export default RecoveryPassword;
