import React, { FC, useEffect, useState } from 'react';
import { Dashboard, Header, AppInfo } from '~/components';
import { Body, Content } from './styles';
import { onSearch } from '~/utils/helpers/search.helper';
import { CLIENTS, useStores, REACT_APP_ENV } from '~/utils';
import { observer } from 'mobx-react';
import { Path } from '~/routes/routes.path';

const Iqd: FC = (): JSX.Element => {
  const { user, routing } = useStores();
  const [apps, setApps] = useState(CLIENTS.PRIME.apps);
  return (
    <Content>
      <Header />
        
      <Body>
        <Dashboard />
      </Body>
      <AppInfo />
    </Content>
  );
};

export default observer(Iqd);
