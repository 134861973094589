import React, { FC, useEffect, useState } from 'react';
import { Accounts, Header, AppInfo, ModalWarning, If } from '~/components';
import { Body, Content } from './styles';
import { onSearch } from '~/utils/helpers/search.helper';
import { CLIENTS, useStores, REACT_APP_UPDATE_WARNING } from '~/utils';
import { observer } from 'mobx-react';
import { Path } from '~/routes/routes.path';
import { getTenantIdCookie } from '~/utils/tenant';

const Home: FC = (): JSX.Element => {
  const { user, routing } = useStores();
  const [apps, setApps] = useState(CLIENTS.PRIME.apps);
  const [tenantApps, setTenantApps] = useState([]);
  const [modalOpened, setModalOpened] = useState(false);

  // const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   e.preventDefault();

  //   const result = onSearch(e.target.value, CLIENTS.PRIME.apps);
  //   setApps(result);
  // };

  const onCheckAuth = async () => {
    const success = await user.onCheckSession();

    if (!success) {
      routing.replace(Path.LOGIN);
    }
  };

  useEffect(() => {
    onCheckAuth();
    fetchTenantApps();
  }, []);
  
  const fetchTenantApps = async () => {
    const tenantId = getTenantIdCookie();
    const response = await user.fetchTenantInfo(tenantId);
    setTenantApps(response);
  };

  const filteredApps = apps.filter(app => tenantApps?.some(selectedApp => selectedApp === app.id));
  return (
    <Content>
      <Header />

      <Body>
        <If condition={modalOpened}>
          <ModalWarning
            opened={modalOpened}
            setOpened={setModalOpened}
          />
        </If>
        {/* <Search onChange={handleSearch} /> */}
        {/* <Accounts apps={apps} /> */}
        <Accounts apps={filteredApps} />
      </Body>
      <AppInfo />
    </Content>
  );
};

export default observer(Home);