import React, { FC } from 'react';
import {
  TextProduct,
  Content,
  ImgIcon
} from './styles';
import { PrimeLogon } from '~/assets/img';

type Props = {};

const Icon: React.FC = () => {
  return (
    <Content>
      <ImgIcon src={PrimeLogon} alt="Portal de Aceleradores e IA" />
      <TextProduct>Portal de Aceleradores e IA</TextProduct>
    </Content>
  );
};

export default Icon;
