import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import { PlayCircleOutlined, HistoryOutlined } from '@ant-design/icons';
import Header from '~/components/Header';
import { OrquestradorContainer, TableContainer, StyledTable, StyledModalTable } from './styles';
import cronstrue from 'cronstrue/i18n';
import CustomModal from '~/components/CustomModal';
import { getTasks, getTaskHistory } from '~/services/api/taskService'; // Atualize este caminho
import parser from 'cron-parser';
import moment from 'moment';
import 'moment/locale/pt-br'; // Importar para formatar datas em PT-BR
import { getTenantId } from '~/utils/tenant'; // Função para pegar o tenant_id do localStorage ou cookie

const OrquestradorRPA = () => {
  const [jobs, setJobs] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);
  const [isLogVisible, setIsLogVisible] = useState<number | null>(null);
  const [isLogModalVisible, setIsLogModalVisible] = useState(false);
  const [logContent, setLogContent] = useState('');

  const tenantId = getTenantId();

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const tasks = await getTasks(tenantId);
        const formattedJobs = tasks.map(task => ({
          id: task.id,
          host: task.last_execution?.host || 'Desconhecido',
          name: task.name,
          periodicidade: translateCronToPT(task.cron),
          lastRun: task.last_execution?.start_time
            ? moment(task.last_execution.start_time).format('DD/MM/YYYY HH:mm:ss')
            : 'Nunca',
          nextExecution: calculateNextExecution(task.last_execution?.start_time, task.cron),
          currentStatus: translateStatusToPT(task.last_execution?.status),
          // Adiciona a coluna 'enabled' que será transformada em "Ativo" ou "Desativado"
          agendamento: task.enabled ? 'Ativo' : 'Desativado',
        }));
        setJobs(formattedJobs);
      } catch (error) {
        console.error('Erro ao buscar tarefas:', error);
      }
    };

    if (tenantId) {
      fetchJobs();
    } else {
      console.error('Tenant ID não encontrado.');
    }
  }, [tenantId]);

  const translateCronToPT = (cronExpression: string) => {
    try {
      return cronstrue.toString(cronExpression, { locale: 'pt_BR' });
    } catch (err) {
      console.error('Erro na tradução da expressão CRON:', err);
      return 'Erro na tradução do cron';
    }
  };

  const calculateNextExecution = (lastRun: string | null, cronExpression: string) => {
    if (!lastRun) return 'Indisponível';

    try {
      const interval = parser.parseExpression(cronExpression, {
        currentDate: new Date(lastRun),
      });
      return moment(interval.next().toString()).format('DD/MM/YYYY HH:mm:ss');
    } catch (err) {
      console.error('Erro ao calcular a próxima execução:', err);
      return 'Erro no cálculo';
    }
  };

  const translateStatusToPT = (status: string) => {
    switch (status) {
      case 'completed':
        return 'Concluído';
      case 'running':
        return 'Em execução';
      case 'failed':
        return 'Falhou';
      case 'pending':
        return 'Pendente';
      default:
        return 'Desconhecido';
    }
  };

  const showModal = async (jobId: number) => {
    try {
      const history = await getTaskHistory(tenantId, jobId.toString());
      const data = history.map(h => {
        const startTime = moment(h.start_time);
        const endTime = h.end_time ? moment(h.end_time) : null;
        const duration = endTime ? moment.duration(endTime.diff(startTime)) : null;
        const formattedDuration = duration
          ? `${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`
          : 'Em andamento';

        return {
          id: h.id,
          start_time: startTime.format('DD/MM/YYYY HH:mm:ss'),
          end_time: endTime ? endTime.format('DD/MM/YYYY HH:mm:ss') : 'Em andamento',
          status: translateStatusToPT(h.status),
          duration: formattedDuration,
          log: h.logs ? h.logs.join('\n') : 'Log não disponível',
        };
      });

      const columns = [
        { title: 'Início', dataIndex: 'start_time', key: 'start_time' },
        { title: 'Fim', dataIndex: 'end_time', key: 'end_time' },
        { title: 'Status', dataIndex: 'status', key: 'status' },
        { title: 'Duração', dataIndex: 'duration', key: 'duration' },
        {
          title: 'Log',
          dataIndex: 'log',
          key: 'log',
          render: (_, record) => (
            <div>
              <Button onClick={() => showLogModal(record.log)}>
                Exibir Log
              </Button>
            </div>
          ),
        },
      ];

      setModalTitle('Histórico da Tarefa');
      setModalContent(
        <div>
          <StyledModalTable
            dataSource={data}
            columns={columns}
            rowKey="id"
            pagination={{ pageSize: 5, position: ['bottomCenter'], showSizeChanger: false }}
          />
        </div>
      );

      setIsModalVisible(true);
    } catch (error) {
      console.error('Erro ao buscar histórico da tarefa:', error);
    }
  };

  const showLogModal = (log: string) => {
    setLogContent(log);
    setIsLogModalVisible(true);
  };

  const handleLogModalCancel = () => {
    setIsLogModalVisible(false);
    setLogContent('');
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setModalContent(null);
  };

  const startJob = (jobId: number) => {
    console.log(`Iniciando o Job com ID: ${jobId}`);
  };

  const columns = [
    {
      title: '',
      dataIndex: 'play',
      render: (_, record) => (
        <Button
          type="primary"
          icon={<PlayCircleOutlined />}
          onClick={() => startJob(record.id)}
          style={{ backgroundColor: 'green', borderColor: 'green' }}
        />
      ),
      width: '5%',
    },
    { title: 'Host', dataIndex: 'host', key: 'host', width: '15%' },
    { title: 'Agendamento', dataIndex: 'agendamento', key: 'agendamento', width: '15%' }, // Coluna ajustada
    { title: 'Nome do Job', dataIndex: 'name', key: 'name', width: '15%' },
    { title: 'Periodicidade', dataIndex: 'periodicidade', key: 'periodicidade', width: '15%' },
    { title: 'Última Execução', dataIndex: 'lastRun', key: 'lastRun', width: '15%' },
    { title: 'Próxima Execução', dataIndex: 'nextExecution', key: 'nextExecution', width: '15%' },
    { title: 'Status', dataIndex: 'currentStatus', key: 'currentStatus', width: '15%' },
    {
      title: 'Dependências',
      dataIndex: 'dependencies',
      render: () => (
        <Button disabled style={{ color: 'black', backgroundColor: '#D3D3D3', borderColor: '#D3D3D3' }}>
          Não Disponível
        </Button>
      ),
      width: '15%',
    },
    {
      title: 'Histórico',
      dataIndex: 'history',
      render: (_, record) => (
        <Button icon={<HistoryOutlined />} onClick={() => showModal(record.id)}>
          Ver Histórico
        </Button>
      ),
      width: '15%',
    },
  ];

  return (
    <div style={{ display: 'block' }}>
      <Header showConfig={false} pageTitle="Orquestrador" />
      <OrquestradorContainer>
        <TableContainer>
          <StyledTable
            dataSource={jobs}
            columns={columns}
            rowKey="id"
            pagination={{ pageSize: 10 }}
            scroll={{ y: 700 }}
          />
        </TableContainer>
      </OrquestradorContainer>

      <CustomModal title={modalTitle} visible={isModalVisible} onCancel={handleCancel}>
        {modalContent}
      </CustomModal>

      {/* Modal de Log */}
      <CustomModal title="Log da Tarefa" visible={isLogModalVisible} onCancel={handleLogModalCancel}>
        <div style={{ whiteSpace: 'pre-wrap' }}>{logContent}</div>
      </CustomModal>
    </div>
  );
};

export default OrquestradorRPA;