import React, { FC } from 'react';
import { Formik } from 'formik';
import { Path } from '~/routes/routes.path';
import { Amplify } from '~/services';
import { alert, useStores, yup } from '~/utils';
import Register from './Register';

type Props = {};

const validate = yup.object().shape({
  email: yup.string().email('Email inválido').required('Email é obrigatório'),
  password: yup
    .string()
    .min(8, 'A senha deve ter pelo menos 8 caracteres')
    .matches(/[A-Z]/, 'A senha deve conter pelo menos uma letra maiúscula')
    .matches(/[a-z]/, 'A senha deve conter pelo menos uma letra minúscula')
    .matches(/[0-9]/, 'A senha deve conter pelo menos um número')
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      'A senha deve conter pelo menos um caractere especial',
    )
    .required('Senha é obrigatória'),
});

const amplify = new Amplify();

const RegisterContainer: FC<Props> = () => {
  const { routing } = useStores();
  const onRegister = async (data: User.SignUP) => {
    const registered = await amplify.signUp(data);

    if (registered) {
      alert({
        message: 'Cadastro realizado com sucesso, código de validação foi enviado para o email.',
        type: 'success',
        position: 'bottom-center',
      });
      setTimeout(() => {
        routing.push(Path.VERIFY_ACCOUNT, { username: data.email });
      }, 1500);
    }
  };

  return (
    <Formik
      onSubmit={onRegister}
      validationSchema={validate}
      initialValues={{ email: '', password: '' }}
    >
      <Register />
    </Formik>
  );
};

export default RegisterContainer;
