import React, { FC, useEffect } from 'react';
import { Formik } from 'formik';
import { Path } from '~/routes/routes.path';
import { Amplify } from '~/services';
import { alert, useStores, yup } from '~/utils';
import RecoveryPassword from './RecoveryPassword';

type Props = {};

const validate = yup.object().shape({
  email: yup.string().email('Email inválido').required('Email é obrigatório'),
  newPassword: yup
    .string()
    .min(8, 'A senha deve ter pelo menos 8 caracteres')
    .matches(/[A-Z]/, 'A senha deve conter pelo menos uma letra maiúscula')
    .matches(/[0-9]/, 'A senha deve conter pelo menos um número')
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      'A senha deve conter pelo menos um caractere especial',
    )
    .required('Senha é obrigatória'),
  code: yup.string().required('Código é obrigatório'),
});

const amplify = new Amplify();

const RecoveryPasswordContainer: FC<Props> = () => {
  const { routing } = useStores();

  const onRecoveryPassword = async (data: User.ResetPasswordConfirm) => {
    const changed = await amplify.resetPasswordConfirm(data);

    if (changed) {
      alert({
        message: 'Senha alterada com sucesso',
        type: 'success',
        position: 'bottom-center',
      });

      routing.push(Path.LOGIN);
    } else {
      alert({
        message: 'Erro ao alterar senha',
        type: 'error',
        position: 'bottom-center',
      });
    }
  };

  useEffect(() => {
    amplify.verifyUser();
  }, []);

  return (
    <Formik
      onSubmit={onRecoveryPassword}
      validationSchema={validate}
      initialValues={{ email: '', code: '', newPassword: '' }}
    >
      <RecoveryPassword />
    </Formik>
  );
};

export default RecoveryPasswordContainer;
