import React from 'react';
import { FooterContainer, Logo, SocialIcons, SocialIcon, Copyright } from './styles';

import LogoImg from '~/assets/img/logo.png';


import FacebookIcon from '~/assets/icons/icon-facebook.svg';
import InstagramIcon from '~/assets/icons/icon-instagram.svg';
import LinkedInIcon from '~/assets/icons/icon-linkedin.svg';
import YouTubeIcon from '~/assets/icons/icon-youtube.svg';

const socialMedia = [
  {
    name: 'Facebook',
    iconPath: FacebookIcon,
    link: 'https://www.facebook.com/PrimeControl',
  },
  {
    name: 'Instagram',
    iconPath: InstagramIcon,
    link: 'https://www.instagram.com/primecontrol_br/',
  },
  {
    name: 'LinkedIn',
    iconPath: LinkedInIcon,
    link: 'https://br.linkedin.com/company/prime-control',
  },
  {
    name: 'YouTube',
    iconPath: YouTubeIcon,
    link: 'https://www.youtube.com/channel/UCf6cnRxQwhr_tu0RW5nugBQ',
  },
];

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <Logo src={LogoImg} alt="Prime Control" />
      
      <SocialIcons>
        {socialMedia.map((icon) => (
          <a key={icon.name} href={icon.link} target="_blank" rel="noopener noreferrer">
            <SocialIcon src={icon.iconPath} alt={icon.name} />
          </a>
        ))}
      </SocialIcons>
      
      <Copyright>
        © Copyright 2024 Produtos Prime Control.
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;