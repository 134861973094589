import React from "react";
import ReactLoading from "react-loading";
import ColorType from "~/theme/colors";
import { Content, Text } from "./styles";

const Loading: React.FC = () => {
  return (
    <Content>
      <Text>Loading</Text>
      <ReactLoading
        type="spin"
        width={20}
        height={20}
        color={ColorType.primary.light}
      />
    </Content>
  );
};

export default Loading;
