import React, { FC, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Amplify } from '~/services';
import { Path } from './routes.path';
import { useStores } from '~/utils'

type Props = {
  render: React.ReactElement;
};

const amplify = new Amplify();

const PublicRoute: FC<Props> = ({ render }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { routing, user } = useStores();

  useEffect(() => {
    const verifyAuthSession = async () => {
      const isUserAuthenticated = await amplify.verifyUser();
      if (isUserAuthenticated) {
        user.onClearUserData();
        const userInfo = await amplify.userInfo();
        user.onSetUserData(userInfo);
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
    }
    verifyAuthSession();
  }, [isLoading]);
  return isLoading ? (render) : (<Navigate to={Path.HOME} />);
  
};

export default PublicRoute;
