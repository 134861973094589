import React, { FC, useState } from "react";
import { Add, Close } from "~/assets/svg";
import {
  Content,
  CustomTextField,
  Header,
  Section,
  SectionFields,
  Text,
  Title,
} from './styles';
import If from "../If";
import { useStores, alert } from '~/utils';

type Props = {
  opened: boolean;
  setOpened: (value: boolean) => void;
};

const ModalWarning: FC<Props> = ({opened, setOpened}) => {
  const onCloseModal = () => {
    setOpened(false);
  }
  
  return (
    <If condition={opened}>
      <Content>
        <Section>
          <Header>
            <Title>
              AVISO DE MANUTENÇÃO NO SISTEMA!!!
            </Title>
            <Close onPress={() => onCloseModal()} color={"#FFF"} />
          </Header>
          <SectionFields>
            <Text>
              No dia 12/09/2024, a partir das 09:00 horas horário de brasília, o sistema ficará indisponível para manutenção.
            </Text>
            <Text>
              Com previsão de retorno as 16:00 horas do mesmo dia.
            </Text>
          </SectionFields>
        </Section>
      </Content>
    </If>
  );
};

export default ModalWarning;
