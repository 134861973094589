import { getTheme, styled } from '~/utils';

const inMobile = getTheme('inMobile');
const primaryDark = getTheme('primary.dark');
const largeSpacing = getTheme('largeSpacing');
const mediumSpacing = getTheme('mediumSpacing');
const smallSpacing = getTheme('smallSpacing');
const extraLargeSpacing = '40px'; 

export const Content = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${primaryDark};
  display: flex;
  flex-direction: column;
`;

export const CarouselContainer = styled.div`
  width: 80%;
  max-width: 1000px; 
  margin: 0 auto; 
  margin-bottom: ${largeSpacing}; 
`;

export const Body = styled.div`
  width: 100%;
  height: 100%;
  padding: ${largeSpacing};
  flex-direction: column;
  color: white;

  @media ${inMobile} {
    padding: ${smallSpacing};
  }

  h1 {
    font-size: 5rem; 
    margin-bottom: ${mediumSpacing};
    text-align: center; 
  }
  h2 {
    font-size: 2rem;
    margin-bottom: ${mediumSpacing};
    text-align: center;
  }
  h3 {
    font-size: 2rem; 
    margin-bottom: ${mediumSpacing};
    text-align: left; 
  }

  p {
    font-size: 1.6rem; 
    line-height: 1.6;
    margin-bottom: ${largeSpacing};
    text-align: left; 
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${mediumSpacing};
`;

export const Tool = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #081e38;
  padding: ${mediumSpacing};
  border-radius: 8px;
  align-items: flex-start;
  gap: 299px; 
  padding-bottom: 12rem;
  
  @media ${inMobile} {
    flex-direction: column;
    padding: ${smallSpacing};
  }
`; 

export const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem; 
  margin-right: ${largeSpacing}; 
  text-align: left; 

  @media ${inMobile} {
    margin-bottom: ${smallSpacing};
    margin-right: 0; 
  }
`;

export const ToolImage = styled.img`
  width: 30%; 
  height: auto;
  border-radius: 8px;
  border: 3px solid #f58220; 
  background-color: ${primaryDark};
  

  @media ${inMobile} {
    width: 100%;
    margin-bottom: ${smallSpacing};
  }
`;

export const ToolTitle = styled.h3`
  margin: 0;
  margin-bottom: 0.5rem; 
  font-size: 3rem; 
  line-height: 1.2; 
  text-align: left;  
`;

export const ToolDescription = styled.p`
  font-size: 1rem; 
  line-height: 1.5; 
  margin: 0; 
  text-align: left;  
`;

export const ButtonLink = styled.a`
  color: white; 
  font-weight: bold;
  text-decoration: none;
  margin-top: ${smallSpacing};
  padding: 0.5rem 1rem;
  border: 2px solid #f58220; 
  background-color: #f58220;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  max-width: fit-content;

  &:hover {
    text-decoration: underline;
  }
`;