import { getTheme, ifStyle, pxToRem, styled } from '~/utils';

const inMobile = getTheme('inMobile');
const inTablet = getTheme('inTablet');
const inDeskop = getTheme('inDesktop');

// Ifs
const isVisible = ifStyle('visible');

// Spacings
const primaryContrast = getTheme('primary.contrast');
const secondaryLight = getTheme('secondary.light');
const secondaryDark = getTheme('secondary.dark');
const tertiaryDark = getTheme('tertiary.dark');

// Radius
const smallRadius = getTheme('smallRadius');

export const Content = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: ${pxToRem(20)};
 

  @media ${inTablet} {
    grid-template-columns: repeat(5, 1fr);
    height: 40%;
    gap: ${pxToRem(20)};
  }

  @media ${inMobile} {
    width: 95%;
    height: 40%;
    grid-template-columns: repeat(2, 1fr);
    gap: ${pxToRem(20)};
  }


`;

type AProps = {
  visible: boolean;
};

export const CardContainer = styled.div`
  position: relative;
  height: ${pxToRem(150)};
`

export const WIPTag = styled.span`
  left: ${pxToRem(-8)};
  top: ${pxToRem(100)};
  justify-content: center;
  width: ${pxToRem(32*4)};
  height: ${pxToRem(32)};
  background-color: ${primaryContrast};
  border-radius: ${smallRadius};
  z-index: 1;
  color: ${secondaryLight};
  align-items: center;
  display: flex;
  position: absolute;
  opacity : 0.90;
  font-size: ${pxToRem(14)};
  cursor: not-allowed;
`

export const Card = styled.div<AProps>`
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${pxToRem(150)};
  border: 0;
  background-color: ${secondaryDark};
  border-radius: ${smallRadius};
  padding-left: ${pxToRem(5)};
  opacity: ${isVisible(0.5)};
  cursor: ${isVisible('not-allowed', 'pointer')};

  &:hover {
    background-color: ${tertiaryDark};
    transition: 1s;
  }
`;

export const Image = styled.img`
  object-fit: contain;
  width: ${pxToRem(150)};
  height: ${pxToRem(150)};
`;
